import {
  STATIC_ASSETS_PATH,
  FULFILLMENT_METHOD_STH,
  FULFILLMENT_METHOD_BOPIS,
  FULFILLMENT_METHOD_BODFS,
  FULFILLMENT_METHOD_BOSS
} from './product-add-on-constants';

export const buildImageURL = (imageName) => `${STATIC_ASSETS_PATH}${imageName}`;

const displayBodfsStoreAssembly = ({
  bodfsAssemblyEligible,
  availableFulfillments,
  isTwoTileEnabled,
  bodfsGrillsAssemblyEnabled
}) => {
  return isTwoTileEnabled
  && bodfsAssemblyEligible
  && bodfsGrillsAssemblyEnabled
  && availableFulfillments.includes(FULFILLMENT_METHOD_BODFS);
};

export const displayAttachLabor = ({
  isInstallationSku,
  inStoreAssemblyAvailable,
  availableFulfillments,
  currentFulfillment,
  isTwoTileEnabled,
  // Flag from backend on if the item is eligible for BODFS assembly
  bodfsAssemblyEligible,
  // FS for BODFS Grill Assembly
  bodfsGrillsAssemblyEnabled
}) => {
  return (
    currentFulfillment && (
      isInstallationSku
      || inStoreAssemblyAvailable
      || displayBodfsStoreAssembly({
        bodfsAssemblyEligible,
        availableFulfillments,
        isTwoTileEnabled,
        bodfsGrillsAssemblyEnabled
      })
    )
  );
};

export const applicablePickUp = (fulfillment) => {
  const pickupService = fulfillment?.fulfillmentOptions?.find((option) => option.type === 'pickup');
  const bopisFulfillment = pickupService?.services?.find((service) => service.type === 'bopis');
  const bossFulfillment = pickupService?.services?.find((service) => service.type === 'boss');
  return [bopisFulfillment ? FULFILLMENT_METHOD_BOPIS : '', bossFulfillment ? FULFILLMENT_METHOD_BOSS : ''];
};

export const applicableDelivery = (fulfillment) => {
  const deliveryService = fulfillment?.fulfillmentOptions?.find((option) => option.type === 'delivery');
  const sthFulfillment = deliveryService?.services?.find((service) => service.type === 'sth');
  const bodfsFulfillment = deliveryService?.services?.find((service) => service.type === 'express delivery');
  return [sthFulfillment ? FULFILLMENT_METHOD_STH : '', bodfsFulfillment ? FULFILLMENT_METHOD_BODFS : ''];
};

export const isAssemblyOrInstallValid = (fulfillment, addOn = {}) => {
  switch (fulfillment) {
  case FULFILLMENT_METHOD_BOPIS:
    return addOn.inStoreAssemblyAvailable;
  case FULFILLMENT_METHOD_STH:
    return addOn.isInstallationSku;
  case FULFILLMENT_METHOD_BODFS:
    return addOn.bodfsAssemblyEligible;
  default:
    return false;
  }
};