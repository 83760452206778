/* eslint-disable no-console */
export const PAYPAL_EVENTS = {
  PAYPAL_SUBMISSION: 'paypal express',
  PAYPAL_SUBMISSION_PIP: 'paypal express pip',
  PAYPAL_PAY_LATER: 'paypal pay later',
  CART_STASH: 'cart add location'
};

let EVENT_LOGGING_ON = true;

const buildItemAnalyticsObject = (eventData) => {
  const item = eventData?.cartRequest?.items?.pickup?.[0] || eventData?.cartRequest?.items?.delivery?.[0];
  return {
    fulfillment: {
      method: item?.type,
      store: item?.type === 'boss' || item?.type === 'bopis' ? item.location : '',
      zip: item?.type === 'sth' ? item?.location : '',
    },
    price: {
      basePrice: eventData?.itemPrice,
    },
    productInfo: {
      sku: item?.itemId,
    },
    quantity: item?.quantity
  };
};

const buildStashAnalyticsObject = (eventData) => {
  const productID = eventData?.items?.pickup?.[0].itemId || eventData?.items?.delivery?.[0].itemId || '';
  return {
    component: '',
    item: [{
      productID,
    }],
    pageType: 'pip',
    section: '',
    sharedSection: 'atc',
    target: 'atc'
  };
};

export const buildAnalyticEvent = (eventName, eventData) => {
  if (eventName === PAYPAL_EVENTS.CART_STASH) {
    return {
      primaryCategory: 'stash',
      eventName,
      eventMode: 'stash',
      stash: buildStashAnalyticsObject(eventData),
    };
  }
  if (eventName === PAYPAL_EVENTS.PAYPAL_SUBMISSION_PIP) {
    return {
      primaryCategory: 'button submission',
      eventName,
      item: buildItemAnalyticsObject(eventData),
      overlayLocation: 'pip'
    };
  }
  return {
    primaryCategory: 'button submission',
    eventName,
    cartViewType: eventData?.cartViewType // required only for paypal express or  paypal pay later
  };
};

const cartCheckoutAdobe = (eventName, eventData) => {
  try {
    if (EVENT_LOGGING_ON) {
      const EVENT_BUS = (typeof window !== 'undefined')
        ? window.top.LIFE_CYCLE_EVENT_BUS : LIFE_CYCLE_EVENT_BUS;
      EVENT_BUS.trigger('cartItem.DD_EVENT',
        buildAnalyticEvent(eventName, eventData)
      );
    }
  } catch (err) {
    console.error('Analytics Error: ', err);
  }
};

export const logAnalytics = ({
  eventName,
  eventData
}) => {
  if (
    eventName === PAYPAL_EVENTS.PAYPAL_SUBMISSION
    || eventName === PAYPAL_EVENTS.PAYPAL_PAY_LATER
    || eventName === PAYPAL_EVENTS.PAYPAL_SUBMISSION_PIP
    || eventName === PAYPAL_EVENTS.CART_STASH) {
    cartCheckoutAdobe(eventName, eventData);
  } else {
    console.error('Event not handled', eventName, eventData);
  }
};

export const getAnalyticsEventName = (hasSavedPaypal, payInFour, fromPage) => {
  if (fromPage === 'pip') {
    return PAYPAL_EVENTS.PAYPAL_SUBMISSION_PIP;
  }
  if (hasSavedPaypal && payInFour) {
    return PAYPAL_EVENTS.PAYPAL_PAY_LATER;
  }
  return PAYPAL_EVENTS.PAYPAL_SUBMISSION;
};
